<template>
    <div class="performances-detail" :class="_getRootClassBind()">
        <div class="performances-detail-content">
            <!-- <a class="back-prv" :href="[isFromPerformance ? '/Headhunting/MyCompany.html#/performance' :'/Headhunting']"><span class="performances-detail-arrow performances-detail-arrow-left"></span>&nbsp;返回</a> -->
            <!-- <div class="performances-detail-tip"><span>{{isFromPerformance ? '业绩' : '工作台'}}</span>&ensp;<span class="performances-detail-arrow performances-detail-arrow-right"></span>&ensp;<span class="key-performance" v-text="path"></span></div> -->
            <div class="performances-detail-main">
                <div class="performances-detail-title">
                    <div class="table-title">
                        <i v-text="title"></i><span v-text="totalCount"></span>
                    </div>

                    <div class="performances-detail-option">
                        <div class="sub-title">
                            <!-- 我的评价状态筛选 -->
                            <!-- <div class="evaluate-select-wrap">
                                <el-select v-if="key == 'recommendationDetails' && (['1','2','3','4','5','6'].includes(achievementDetailType))" v-model="evaluateStatus" placeholder="请选择评价状态" clearable @change="evaluateStatusChange">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="待评价" value="1"></el-option>
                                    <el-option label="已评价" value="2"></el-option>
                                </el-select>
                            </div> -->

                            <!-- 时间段选择 -->
                            <div class="date-wrap triangle-drop" :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                                <el-date-picker
                                    v-model="dateRange"
                                    type="daterange"
                                    start-placeholder="开始日期"
                                    range-separator="-"
                                    end-placeholder="结束日期"
                                    size="small"
                                    :editable="false"
                                    align="center"
                                    format="yyyyMMdd"
                                    @change="handleDate"
                                    @focus="handleFocusDate"
                                    @blur="handleBlurDate"
                                    :clearable="false"
                                    :picker-options="expireTimeOption"
                                    popper-class="performance-data-range">
                                </el-date-picker>
                            </div>
                            <!-- 成员选择 -->
                            <div 
                                v-if="pageType == 'teamData'"
                                class="employee-select-wrap triangle-drop" 
                                :class="teamMemberPopoverVisible?'triangle-drop-up':'triangle-drop-down'">
                                <el-popover
                                    popper-class="team-member-popover delivery-team-member-popover"
                                    placement="bottom-end"
                                    width="700"
                                    trigger="click"
                                    v-model="teamMemberPopoverVisible"
                                    >
                                    <team-member-select
                                        ref="teamSelect"
                                        :isAllMembers="true"
                                        :isShowDimission="false"
                                        :date="[json.startDate, json.endDate]"
                                        :isHistoryData="true"
                                        :visible="teamMemberPopoverVisible"
                                        :selected="json.userIds"
                                        @item-select-cb="employeeSelect"
                                    ></team-member-select>
                                    <div slot="reference" class="employee-select-text">{{selectedMembersText}}</div>
                                </el-popover>
                            </div>
                            <!-- 选择客户 -->
                            <div v-if="achievementDetailType == 12 || achievementDetailType == 13 || achievementDetailType == 14" class="client-select-wrap">
                                <el-select 
                                    class="select-client triangle-drop" 
                                    :class="clientVisible?'triangle-drop-up':'triangle-drop-down'"
                                    v-model="clientValue" 
                                    placeholder="请选择客户"
                                    @change="getListData"
                                    @focus="clientSelectFocus"
                                    @blur="clientSelectBlur">
                                    <el-option
                                        class="client-select-item"
                                        v-for="item in clientOptions"
                                        :title="item.name"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        
                        <div v-if="key == 'callRecordDetails'" class="performances-detail-export-new" @mouseover="handleSwitchExport('enter')" @mouseout="handleSwitchExport('out')">
                            <el-button 
                                type="primary" 
                                class="performances-detail-el-export"
                                @click="handleExport('detail')"
                                :disabled="isLoadingExport"
                                :loading="isLoadingExport">导出明细</el-button>
                            <el-button 
                                type="primary" 
                                class="performances-detail-el-export"
                                @click="handleExport('list')"
                                :disabled="isLoadingExportTable"
                                :loading="isLoadingExportTable">导出统计表</el-button>
                        </div>
                        <div v-else class="performances-detail-export-new">
                            <!-- && key != 'trackingListDetails' -->
                            <!-- v-if="key != 'callRecordDetails'"  -->
                            <el-button 
                                type="primary" 
                                class="performances-detail-el-export"
                                @click="handleExport('detail')"
                                :disabled="isLoadingExport"
                                :loading="isLoadingExport">导出明细</el-button>
                        </div>
                    </div>
                </div>
                
                <!-- trackingList 饼图 -->
                <tracking-list-chart
                    v-if="key == 'trackingListDetails'"
                    ref="trackingListChart"
                    :loading="loadChart"
                >
                </tracking-list-chart>

                <div class="order-container table-content" v-loading="listLoading">
                    <div class="order-wrapper">
                        <p class="order-wrapper-empty" v-if="dataFetched && listData.length == 0">暂无数据</p>
                        <ul class="order visible order-full">
                            <li class="order-item" v-for="(item, index) in listData" :key="Math.random().toString() + index">
                                <!--人才创建-->
                                <template v-if="key == 'candidateDetails'">
                                    <span class="item" v-text="item.realName" :title="item.realName"></span>
                                    <span class="item" v-text="item.createTime" :title="item.createTime"></span>
                                    <span class="item" v-text="item.createType"></span>
                                    <span class="item" :title="item.candidateName">
                                        <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName"></a>
                                        <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                    </span>
                                    <span class="item" v-text="item.title" :title="item.title"></span>
                                    <span class="item" v-text="item.company" :title="item.company"></span>
                                </template>

                                <!--寻访记录-->
                                <template v-if="key == 'candidateInquiryLogDetails'">
                                    <span class="item" v-text="item.realName" :title="item.realName"></span>
                                    <span class="item" v-text="item.createTime" :title="item.createTime"></span>
                                    <span class="item" v-text="item.isValid"></span>
                                    <span class="item" v-text="item.sourceFrom"></span>
                                    <span class="item" :title="item.candidateName">
                                        <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                        <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName"></a>
                                        <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                    </span>
                                    <span class="item" v-text="item.title" :title="item.title"></span>
                                    <span class="item" v-text="item.company" :title="item.company"></span>
                                    <span class="item" v-text="item.content" :title="item.content"></span>
                                    <span class="item" :title="item.recommendationInfo" v-text="item.recommendationInfo"></span>
                                </template>

                                <!--通话记录-->
                                <template v-if="key == 'callRecordDetails'">
                                    <span class="item item-listen" v-text="item.realName" :title="item.realName"></span>
                                    <span class="item item-listen" v-text="item.callStartTime" :title="item.callStartTime"></span>
                                    <span class="item item-listen" v-text="item.isValidCall"></span>
                                    <!--<span class="item" v-text="item.duration"></span>-->
                                    <span class="item item-listen-record">
                                        <record-listen 
                                            v-if="item.url && item.url !== '通话录音'" 
                                            :audio-url="item.url" 
                                            :duration="item.duration">
                                        </record-listen>
                                        <span v-if="item.url && item.url === '通话录音'" v-text="item.url">
                                            <a target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName" @click="handleCandidate(item)"></a>
                                        </span>
                                        <span v-if="!item.url && item.hasOwnProperty('url')"></span>
                                    </span>
                                    <span class="item item-listen" v-text="item.isCandidate"></span>
                                    <span class="item item-listen item-listen-long">
                                        <a target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName" v-if="item.isCandidate == '是'"></a>
                                        <span style="cursor: not-allowed" :title="item.candidateName" v-else>{{item.candidateName}}</span>
                                    </span>
                                    <span class="item" v-text="item.title" :title="item.title"></span>
                                    <span class="item" v-text="item.company" :title="item.company"></span>
                                    <span class="item item-listen item-listen-inquiry" v-text="item.inquiryLogContent" :title="item.inquiryLogContent"></span>
                                </template>

                                <!--职位创建、更新-->
                                <template v-if="key == 'jobDetails'">
                                    <template v-if="achievementDetailType == 1">
                                        <span class="item" v-text="item.realName" :title="item.realName"></span>
                                        <span class="item" v-text="item.jobCreateType"></span>
                                        <span class="item">
                                            <!-- <a
                                                :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:void(0)' "
                                                :title="item.jobName"
                                                target="_blank"
                                            >{{item.jobName}}</a> -->
                                            <a
                                                v-if="item.jobId"
                                                :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:void(0)' "
                                                :title="item.jobName"
                                                :target="item.jobId ? '_blank' : '_self'"
                                                style="color: #666"
                                            >{{item.jobName}}</a>
                                            <span v-else>{{item.jobName}}</span>
                                        </span>
                                        <span class="item" v-text="item.customerName" :title="item.customerName"></span>
                                        <template v-if="isCFUser">
                                            <span class="item" v-text="item.customerDomain || '-'" :title="item.customerDomain || '-'"></span>
                                        </template>
                                        <!-- <span class="item" v-text="item.customerDomain || '-'" :title="item.customerDomain || '-'"></span> -->
                                        <span class="item" v-text="item.createTime" :title="item.createTime"></span>
                                    </template>
                                    <template v-if="achievementDetailType == 2">
                                        <span class="item" v-text="item.realName" :title="item.realName"></span>
                                        <span class="item" v-text="item.updateTime" :title="item.updateTime"></span>
                                        <span class="item" v-text="item.updateType"></span>
                                        <span class="item">
                                            <!-- <a
                                                :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:void(0)' "
                                                :title="item.jobName"
                                                target="_blank"
                                            >{{item.jobName}}</a> -->
                                            <a
                                                v-if="item.jobId"
                                                :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:void(0)' "
                                                :title="item.jobName"
                                                :target="item.jobId ? '_blank' : '_self'"
                                                style="color: #666"
                                            >{{item.jobName}}</a>
                                            <span v-else>{{item.jobName}}</span>
                                        </span>
                                        <span class="item" v-text="item.updateContent" :title="item.updateContent"></span>
                                    </template>
                                </template>

                                <!-- 接单 -->
                                <template v-if="key == 'orderDetails'">
                                    <span class="item item-order-detail" v-text="item.realName" :title="item.realName"></span>
                                    <span class="item item-order-detail" v-text="item.orderTime" :title="item.orderTime"></span>
                                    <span class="item item-order-detail">
                                        <!-- <a
                                            :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:void(0)' "
                                            :title="item.jobName"
                                            target="_blank"
                                        >{{item.jobName}}</a> -->
                                        <a
                                            v-if="item.jobId"
                                            :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:void(0)' "
                                            :title="item.jobName"
                                            :target="item.jobId ? '_blank' : '_self'"
                                            style="color: #666"
                                        >{{item.jobName}}</a>
                                        <span v-else>{{item.jobName}}</span>
                                    </span>
                                    <!-- <span class="item item-order-detail" v-text="item.emergencyDegree"></span> -->
                                    <span class="item item-order-detail" v-text="item.recruitingCount"></span>
                                    <span class="item item-order-detail" v-text="item.customerName" :title="item.customerName"></span>
                                    <template v-if="isCFUser">
                                        <span class="item item-order-detail" v-text="item.customerDomain || '-'" :title="item.customerDomain || '-'"></span>
                                    </template>
                                    <span class="item item-order-detail" v-text="item.jobCreator" :title="item.jobCreator"></span>
                                    <span class="item item-order-detail" v-text="item.recommendationCount"></span>
                                    <span class="item item-order-detail" v-text="item.acceptCount"></span>
                                    <span class="item item-order-detail" v-text="item.interviewCount"></span>
                                    <span class="item item-order-detail" v-text="item.offerCount"></span>
                                </template>

                                <!--简历查看-->
                                <template v-if="key == 'candidateViewDetails'">
                                    <span class="item" v-text="item.realName" :title="item.realName"></span>
                                    <span class="item" v-text="item.viewCount"></span>
                                    <span class="item" v-if="index == 0" v-text="item.candidateName"></span>
                                    <span class="item" v-if="index != 0">
                                        <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                        <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName" @click="handleCandidate(item)"></a>
                                        <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                    </span>
                                    <span class="item" v-text="item.title" :title="item.title"></span>
                                    <span class="item" v-text="item.company" :title="item.company"></span>
                                    <span class="item" v-text="item.viewType"></span>
                                    <span class="item" v-text="item.inqueryLog" :title="item.inqueryLog"></span>
                                    <span class="item" v-text="item.callLog" :title="item.callLog"></span>
                                </template>

                                <!--简历更新-->
                                <template v-if="key == 'candidateUpdateDetails'">
                                    <span class="item" v-text="item.realName" :title="item.realName"></span>
                                    <span class="item" v-text="item.updateTime" :title="item.updateTime"></span>
                                    <span class="item" v-if="index == 0" v-text="item.candidateName"></span>
                                    <span class="item" v-if="index != 0">
                                        <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                        <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName" @click="handleCandidate(item)"></a>
                                        <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                    </span>
                                    <span class="item" v-text="item.title" :title="item.title"></span>
                                    <span class="item" v-text="item.company" :title="item.company"></span>
                                    <span class="item" v-text="item.updateType"></span>
                                </template>

                                <!--简历推荐-->
                                <template v-if="key == 'recommendationDetails' || key == 'generalCoordinatorRecommendationDetails'">
                                    <!-- 推荐人才 -->
                                    <template v-if="achievementDetailType == 1 || achievementDetailType == 2 || achievementDetailType == 3">
                                        <span class="item" v-text="item.realName" :title="item.realName"></span>
                                        <span class="item item-recommend-id" v-if="key == 'recommendationDetails'" v-text="item.recommendationId" :title="item.recommendationId"></span>
                                        <span class="item item-recommend-time" v-text="item.recommendTime" :title="item.recommendTime"></span>
                                        <span class="item item-recommend-time" v-text="item.operationTime" :title="item.operationTime"></span>
                                        <span class="item item-recommend-time" v-text="item.status" :title="item.status"></span>
                                        <span class="item" :title="item.candidateName">
                                            <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                            <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName"></a>
                                            <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                        </span>
                                        <span class="item" v-text="item.company" :title="item.company"></span>
                                        <span class="item" v-text="item.title" :title="item.title"></span>
                                        <span class="item" v-text="item.jobSource" v-if="key == 'recommendationDetails'" :title="item.jobSource"></span>
                                        <span class="item" v-text="item.customerName" :title="item.customerName"></span>
                                        <template v-if="isCFUser">
                                            <span 
                                                class="item item-order-detail" 
                                                v-text="item.customerDomain || '-'" 
                                                v-if="key == 'recommendationDetails'"
                                                :title="item.customerDomain || '-'"
                                                ></span>
                                        </template>
                                        <span class="item" v-text="item.department" :title="item.department"></span>
                                        <span class="item">
                                            <!-- <a :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:;;;' " :title="item.jobName" target="_blank">{{ item.jobName }}</a> -->
                                            <a  v-if="item.jobId" :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:;' " :title="item.jobName" :target="item.jobId ? '_blank' : '_self'" style="color: #666">{{ item.jobName }}</a>
                                            <span v-else>{{ item.jobName }}</span>
                                        </span>
                                        <span class="item" v-text="item.acceptUser" :title="item.acceptUser"></span>
                                        <span class="item" v-text="item.candidateSourceChannel" :title="item.candidateSourceChannel"></span>
                                        <!-- 业务分类 -->
                                        <span class="item" v-if="isCFUser" v-text="item.businessClassificationName || '-'" :title="item.businessClassificationName"></span>
                                        <span class="item" title="我的评价" v-if="index == 0">我的评价</span>
                                        <span class="item" v-if="index != 0">{{ item.evaluateStatus }}</span>
                                        <span class="item" title="操作" v-if="index == 0">操作</span>
                                        <span class="item" v-if="index != 0">
                                            <a :href="`/#/Job/${item.jobId}/recommendation/${item.recommendationId}?recommendDate=${item.recommendTime}`" target="_blank">查看</a>
                                        </span>
                                    </template>

                                    <!-- 人才推荐 -->
                                    <template v-if="achievementDetailType == 4 || achievementDetailType == 5 || achievementDetailType == 6 || achievementDetailType == 12">
                                        <span class="item item-job" v-text="item.realName" :title="item.realName"></span>
                                        <span class="item item-recommend-id" v-if="key == 'recommendationDetails'" v-text="item.recommendationId" :title="item.recommendationId"></span>
                                        <span class="item item-recommend-time" v-text="item.recommendTime" :title="item.recommendTime"></span>
                                        <span class="item item-recommend-time" v-text="item.operationTime" :title="item.operationTime"></span>
                                        <span class="item item-interview" v-if="index == 0 && achievementDetailType == 5">推荐主城市</span>
                                        <span class="item item-recommend-time" v-if="index != 0 && achievementDetailType == 5" :title="item.location">{{ item.location?.length > 0 ? item.location.join() : '-' }}</span>
                                        <span class="item item-interview" v-if="index == 0 && achievementDetailType == 5">推荐其他城市</span>
                                        <span class="item item-recommend-time" v-if="index != 0 && achievementDetailType == 5" :title="item.locations">{{ item.locations?.length > 0 ? item.locations.join() : '-' }}</span>
                                        <span class="item item-recommend-status" v-text="item.status" :title="item.status"></span>
                                        <span class="item" :title="item.candidateName">
                                            <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                            <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName"></a>
                                            <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                        </span>
                                        <span class="item" v-text="item.company" :title="item.company"></span>
                                        <span class="item" v-text="item.title" :title="item.title"></span>
                                        <span class="item" v-text="item.jobSource" v-if="key == 'recommendationDetails'" :title="item.jobSource"></span>
                                        <span class="item" 
                                            v-text="item.candidateCreator" 
                                            :title="item.candidateCreator"></span>
                                        <span class="item item-recommend-time" 
                                            v-text="item.candidateCreated" 
                                            :title="item.candidateCreated"></span>
                                        <span class="item item-job" v-text="item.customerName" :title="item.customerName"></span>
                                        <template v-if="isCFUser">
                                            <span 
                                                class="item item-order-detail" 
                                                v-text="item.customerDomain || '-'" 
                                                v-if="key == 'recommendationDetails'"
                                                :title="item.customerDomain || '-'"
                                                >
                                            </span>
                                        </template>
                                        <span class="item" v-if="pageType=='teamData'" v-text="item.department" :title="item.department"></span>
                                        <span class="item item-job" :title="item.jobName">
                                            <!-- <a :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:;;;' " :title="item.jobName" target="_blank">{{ item.jobName }}</a> -->
                                            <a v-if="item.jobId" :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:;;;' " :title="item.jobName" :target="item.jobId ? '_blank' : '_self'" style="color: #666">{{ item.jobName }}</a>
                                            <span v-else>{{ item.jobName }}</span>
                                        </span>
                                        <span class="item" v-text="item.acceptUser" :title="item.acceptUser"></span>
                                        <span class="item" v-text="item.candidateSourceChannel" :title="item.candidateSourceChannel"></span>
                                        <!-- 业务分类 -->
                                        <span class="item" v-if="isCFUser && pageType=='teamData'" v-text="item.businessClassificationName || '-'" :title="item.businessClassificationName"></span>
                                        <template v-if="pageType=='teamData'">
                                            <span class="item" title="我的评价" v-if="index == 0">我的评价</span>
                                            <span class="item" v-if="index != 0">{{ item.evaluateStatus }}</span>
                                        </template>
                                        <template v-if="pageType=='teamData'">
                                            <span class="item" title="操作" v-if="index == 0">操作</span>
                                            <span class="item" v-if="index != 0">
                                                <a :href="`/#/Job/${item.jobId}/recommendation/${item.recommendationId}?recommendDate=${item.recommendTime}`" target="_blank">查看</a>
                                            </span>
                                        </template>
                                    </template>

                                    <!-- 面试通过 -->
                                    <template v-if="achievementDetailType == 7 || achievementDetailType == 8 || achievementDetailType == 9 || achievementDetailType == 13">
                                        <span class="item" v-text="item.realName" :title="item.realName"></span>
                                        <span class="item item-recommend-id" v-if="key == 'recommendationDetails'" v-text="item.recommendationId" :title="item.recommendationId"></span>
                                        <span class="item item-interview" v-if="index == 0">推荐时间</span>
                                        <span class="item item-recommend-time" v-if="index != 0" v-text="item.recommendTime" :title="item.recommendTime"></span>
                                        <span class="item item-interview" v-if="index == 0 && achievementDetailType == 7">推荐主城市</span>
                                        <span class="item item-recommend-time" v-if="index != 0 && achievementDetailType == 7" :title="item.location">{{ item.location?.length ? item.location.join() : '-' }}</span>
                                        <span class="item item-interview" v-if="index == 0 && achievementDetailType == 7">推荐其他城市</span>
                                        <span class="item item-recommend-time" v-if="index != 0 && achievementDetailType == 7" :title="item.locations">{{ item.locations?.length ? item.locations.join() : '-' }}</span>
                                        <span class="item item-recommend-time" v-text="item.recommenderCFMedTime" :title="item.recommenderCFMedTime"></span>
                                        <span class="item item-recommend-time" v-text="item.jobOwnerCFMedTime" :title="item.jobOwnerCFMedTime"></span>
                                        <span class="item" v-text="item.status"></span>
                                        <span class="item" :title="item.candidateName">
                                            <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                            <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName"></a>
                                            <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                        </span>
                                        <span class="item" v-text="item.title" :title="item.title"></span>
                                        <span class="item" v-text="item.jobSource" v-if="key == 'recommendationDetails'" :title="item.jobSource"></span>
                                        <span class="item" v-text="item.company" :title="item.company"></span>
                                        <span class="item" v-text="item.customerName" :title="item.customerName"></span>
                                        <template v-if="isCFUser">
                                            <span 
                                                class="item" 
                                                v-text="item.customerDomain || '-'" 
                                                v-if="key == 'recommendationDetails'"
                                                :title="item.customerDomain || '-'"
                                                >
                                            </span>
                                        </template>
                                        <span class="item" v-if="pageType=='teamData'" v-text="item.department" :title="item.department"></span>
                                        <span class="item">
                                            <!-- <a :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:;;;' " :title="item.jobName" target="_blank">{{ item.jobName }}</a> -->
                                            <a v-if="item.jobId" :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:;' " :title="item.jobName" :target="item.jobId ? '_blank' : '_self'" style="color: #666">{{ item.jobName }}</a>
                                            <span v-else>{{ item.jobName }}</span>
                                        </span>
                                        <span class="item" v-text="item.acceptUser" :title="item.acceptUser"></span>
                                        <span class="item" v-text="item.candidateSourceChannel" :title="item.candidateSourceChannel"></span>
                                        <!-- 业务分类 -->
                                        <span class="item" v-if="isCFUser && pageType=='teamData'" v-text="item.businessClassificationName || '-'" :title="item.businessClassificationName"></span>
                                    </template>

                                    <!-- offer -->
                                    <template v-if="achievementDetailType == 10 || achievementDetailType == 11">
                                        <span class="item" v-text="item.realName" :title="item.realName"></span>
                                        <span class="item" v-if="index == 0" v-text="item.candidateName"></span>
                                        <span class="item" v-if="index != 0">
                                            <anchor v-if="item.detailUrl" :enable-external="true" :href="item.detailUrl" v-text="item.candidateName"></anchor>
                                            <a v-if="!item.detailUrl && item.candidateId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.candidateId}`}" v-text="item.candidateName" :title="item.candidateName" @click="handleCandidate(item)"></a>
                                            <span v-if="!item.detailUrl && !item.candidateId" v-text="item.candidateName" :title="item.candidateName"></span>
                                        </span>
                                        <span class="item" v-text="item.title" :title="item.title"></span>
                                        <span class="item" v-text="item.jobSource" :title="item.jobSource"></span>
                                        <span class="item" v-if="index == 0">工作地点</span>
                                        <span class="item" v-if="index != 0" v-text="item.locations?.join()" :title="item.locations"></span>
                                        <span class="item" v-text="item.company" :title="item.company"></span>
                                        <span class="item" v-if="index == 0">推荐至公司</span>
                                        <span class="item" v-if="index != 0" v-text="item.customerName" :title="item.customerName"></span>
                                        <template v-if="isCFUser">
                                            <span class="item" v-if="index == 0">所属领域</span>
                                            <span 
                                                class="item" 
                                                v-if="index != 0"
                                                v-text="item.customerDomain || '-'" 
                                                :title="item.customerDomain || '-'"
                                                >
                                            </span>
                                        </template>
                                        <span class="item" v-text="item.department" :title="item.department"></span>
                                        <span class="item" v-if="index == 0">推荐至职位</span>
                                        <span class="item" v-if="index != 0">
                                            <!-- <a :href="item.jobId ? '/Headhunting/MyCompany.html#/Job/' + item.jobId : 'javascript:;;;' " :title="item.jobName" target="_blank">{{ item.jobName }}</a> -->
                                            <a v-if="item.jobId" :href="item.jobId ? '/#/Job/' + item.jobId : 'javascript:;;;' " :title="item.jobName" :target="item.jobId ? '_blank' : '_self'" style="color: #666">{{ item.jobName }}</a>
                                            <span v-else>{{ item.jobName }}</span>
                                        </span>
                                        <span class="item" v-if="achievementDetailType == 10 && index == 0">offer时间</span>
                                        <span class="item" v-if="achievementDetailType == 11 && index == 0">入职时间</span>
                                        <span class="item" v-if="index != 0 && achievementDetailType == 10" v-text="item.operationTime" :title="item.operationTime"></span>
                                        <span class="item" v-if="index != 0 && achievementDetailType == 11" :title="item.hireTime | dataFilter"> {{ item.hireTime | dataFilter}}</span>
                                        <span class="item" v-text="item.candidateSourceChannel" :title="item.candidateSourceChannel"></span>
                                        <!-- 业务分类 -->
                                        <span class="item" v-if="isCFUser" v-text="item.businessClassificationName || '-'" :title="item.businessClassificationName"></span>
                                    </template>
                                </template>

                                <!--TrackingList-->
                                <template v-if="key == 'trackingListDetails'">
                                    <span class="item" v-text="item.userName" :title="item.userName"></span>
                                    <span class="item" v-text="transformTeam(item.teams)" :title="item.teams"></span>
                                    <span class="item" v-if="index == 0" v-text="item.realName"></span>
                                    <span class="item item-name" v-if="index != 0" :title="item.realName">
                                        <a v-if="item.trackingSource == '企业库' && item.resumeId" target="_blank" v-bind="{ href: `#/candidateDetail/${item.redirectId ? item.redirectId : item.resumeId}`}" v-text="item.realName" :title="item.realName"></a>
                                        <!-- <span v-if="item.trackingSource != '企业库'" v-text="item.realName" :title="item.realName"></span> -->
                                        <a v-if="item.trackingSource != '企业库'" :href="`#/candidateDetail/${item.redirectId ? item.redirectId : item.resumeId}`" target="_blank" v-text="item.realName" :title="item.realName"></a>
                                    </span>
                                    <!-- 寻访记录 -->
                                    <span class="item">
                                        <template v-if="item.inquiryLogsCount">
                                            <!-- @update-item="handleUpdateItem" -->
                                            <inquiry-log
                                                :type="'trakingList'"
                                                :resume="item"
                                                v-if="item.inquiryLogsCount>0"
                                            ></inquiry-log>
                                            <span v-else>{{item.inquiryLogsCount}}</span>
                                        </template>
                                        <template v-else> - </template>
                                    </span>
                                    <span class="item" v-text="item.title || '-'" :title="item.title"></span>
                                    <span class="item" v-text="item.company || '-'" :title="item.company"></span>
                                    <span class="item" v-text="item.standardizedCompany || '-'" :title="item.standardizedCompany"></span>
                                    <span class="item" v-text="item.city || '-'" :title="item.city"></span>
                                    <span class="item degree-item" v-bind:title="item.degree | degreeFilter">{{item.degree | degreeFilter}}</span>
                                    <!-- <span class="item firm-recommendation-count" v-text="item.firmRecommendationCount" :title="item.firmRecommendationCount"></span> -->
                                    <span class="item" v-text="item.school || '-'" :title="item.school"></span>
                                    <span class="item" v-text="item.yearOfExperience || '-'" :title="item.yearOfExperience"></span>
                                    <span class="item" v-text="item.age" :title="item.age"></span>
                                    <span class="item" v-text="item.userRecommendationCount" :title="item.userRecommendationCount"></span>
                                    <span class="item" v-text="item.trackingDate ? item.trackingDate.slice(0, 10) : '-'" :title="item.trackingDate ? item.trackingDate.slice(0, 10) : '-'"></span>
                                    <!-- <span class="item" v-text="item.trackingSource || '-'" :title="item.trackingSource"></span> -->
                                    <span class="item" v-text="item.recommendationTime ? item.recommendationTime.slice(0, 10) : '-'" :title="item.recommendationTime ? item.recommendationTime.slice(0, 10) : '-'"></span>
                                </template>
                            </li>
                        </ul>
                    </div>
                    <div class="work-table-footer">
                        <el-pagination :current-page="page" :page-sizes="[15, 20, 50]" :page-size="pageSize" class="el-pagination-workTable" layout="total, sizes, prev, pager, next, slot" :total="pagerTotalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                            <span class="pagination-text">
                                <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                                <span @click="handlePagerJump">跳转</span>
                            </span>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {performances_detail_new, user} from '#/js/config/api.json';
    import { errorMessage, isUndefined } from '#/js/util/tools.js';
    import performancesMap from './performancesMap-vue.json';
    import moment from 'moment';

    import RecordListen from '#/component/common/record-listen.vue';
    import TrackingListChart from './component/tracking-list-chart.vue';
    import TeamMemberSelect from '#/component/common/team-member-select.vue';

    import resumeMergeService from '#/js/service/resumeMerge.js';
    import performanceSetService from '#/js/service/performanceSetService.js';
    import {downloadFile} from '#/js/util/fileUtil.js'

    import InquiryLog from '../talent-pool/component/inquiryLog.vue';

    const degreeMap = {
        3: '本科',
        4: '硕士',
        6: '博士',
        2: '大专',
        0: '-',
        5: 'MBA'
    };

    const DEFAULT_MAP = {
        candidateDetails: {
            1: '人才创建数',
            2: '完整简历数',
            3: 'Profile数'
        },
        candidateInquiryLogDetails: {
            1: '有效寻访数',
            2: '添加寻访数'
        },
        callRecordDetails: {
            1: '拨打电话数',
            2: '有效通话数'
        },
        jobDetails: {
            1: '创建职位数',
            2: '职位关键信息更新数'
        },
        orderDetails: {
            1: '接单数',
            2: '内部职位接单数',
            3: '外部职位接单数'
        },
        recommendationDetails: {
            1: '推荐人才数',
            2: '接受推荐人才数',
            3: '推荐淘汰人才数',
            4: '人才推荐数',
            5: '接受推荐数',
            6: '推荐淘汰数',
            8: '面试通过数',
            7: '面试人才数',
            9: '面试失败数',
            10: 'offer数',
            11: '入职数',
            12: '综合协调人客户推荐数',
            13: '综合协调人客户面试数',
            14: '综合协调人客户offer数'
        },
        generalCoordinatorRecommendationDetails: {
            12: '综合协调人客户推荐数',
            13: '综合协调人客户面试数',
            14: '综合协调人客户offer数'
        },
        candidateViewDetails: {
            1: '查看简历数',
            2: '查看联系方式数'
        },
        candidateUpdateDetails: {
            1: '全部更新',
            2: '重大更新'
        },
        trackingListDetails: 'TrackingList',
    }

    export default {
        components: {
            RecordListen,
            TrackingListChart,
            TeamMemberSelect,
            InquiryLog,
        },
        data() {
            let _minTime = null,
                _maxTime = new Date().getTime();
            return {
                evaluateStatus: '',

                clientOptions: [],
                clientValue: '',
                page: 1,
                pageSize: 15,
                pagerJump: 0,
                totalCount: 0,  // 记录数
                pagerTotalCount: 0,  // 分布总数
                listData: [],
                listLoading: false,
                dataFetched: false,
                json: {},
                data: {},
                key: '',
                path: '',
                title: '',
                exportFold: true,
                isAdmin: false,
                achievementDetailType: '',
                CandidateViewDetailType: '',
                CandidateUpdateDetailType: '',
                exportUrl: '',
                listUrl: '',
                isFromPerformance: true,
                dateRange: [],
                isFocusDate: false,//用于控制日期的三角
                isLoadingExport: false, // 导出Loading状态
                isLoadingExportTable: false, // 导出Loading状态
                teamMemberPopoverVisible: false,
                clientVisible: false, //用于控制客户的三角
                userIds: [],
                selectedMembersText: '全部成员',
                loadChart: false,  
                expireTimeOption: {
                    onPick: time => {
                        // 如果选择了只选择了一个时间
                        if (!time.maxDate) {
                            let _nDays = this.key == 'trackingListDetails' ? 31: 365;
                            let timeRange = _nDays*24*60*60*1000, // *天
                                _dNow = new Date().getTime();

                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        // onPick后触发
                        // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
                        if(_minTime && _maxTime){
                            return time.getTime() < _minTime || time.getTime() > _maxTime;
                        }
                        if(!_minTime && _maxTime) {
                            return time.getTime() > _maxTime;
                        }
                    },
                    // disabledDate(date) {
                    //     return date.getTime() > Date.now();
                    // },
                    shortcuts: [{
                        text: '昨天',
                        onClick(picker) {
                            const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '最近7天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近30天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本周',
                        onClick(picker) {
                            const start = moment().weekday(0).format('YYYY-MM-DD');
                            const end = moment().weekday(6).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                            const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '上月',
                        onClick(picker) {
                            const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                            const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                    // ,{
                    //     text: '今年',
                    //     onClick(picker) {
                    //         const start = moment().year() + '-01-01';
                    //         const end = moment().format('YYYY-MM-DD');
                    //         picker.$emit('pick', [start, end]);
                    //     }
                    // }
                },
                pageType: '',   //personalData: 个人数据， teamData: 团队数据
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            notCurrentUser() {
                return this.json.memberIds.length == 1 && this.json.memberIds[0] == this.userInfo.userId ? false : true;
            },
            isCFUser() {
                return this.userInfo.isCFUser;
            },
        },
        filters: {
            degreeFilter: function(degree) {
                if(degree == '学历') {
                    return '学历';
                }else {
                    return degreeMap[degree] || '-';
                }
            },
            dataFilter(val) {
                return moment(val).format('YYYY-MM-DD')
                return moment(val).format('YYYY-MM-DD HH:mm:SS')
            }
        },

        mounted() {
            this.checkAdmin();
            let fetchUrl = '';

            if(sessionStorage.getItem('perpormancesDetailData')) {
                this.json = JSON.parse(sessionStorage.getItem('perpormancesDetailData'));
            }else if(localStorage.getItem('perpormancesDetailData')) {
                let jsonStr = localStorage.getItem('perpormancesDetailData');
                sessionStorage.setItem('perpormancesDetailData', jsonStr);

                this.json = JSON.parse(sessionStorage.getItem('perpormancesDetailData'));
            }

            this.key = this.json && this.json.key;
            this.achievementDetailType = this.json && this.json.achievementDetailType;
            this.CandidateViewDetailType = this.json && this.json.CandidateViewDetailType;
            this.CandidateUpdateDetailType = this.json && this.json.CandidateUpdateDetailType;
            this.isFromPerformance = this.json && this.json.isFromPerformance;
            this.pageType = (this.json && this.json.pageType) || '';

            switch(this.key) {
                case 'candidateDetails':
                    this.exportUrl = performances_detail_new.export_candidate_details;
                    this.listUrl = performances_detail_new.candidate_detail;
                    break;
                case 'candidateInquiryLogDetails':
                    this.exportUrl = performances_detail_new.export_inquiry_log_details;
                    this.listUrl = performances_detail_new.candidate_inquiry_log_detail;
                    break;
                case 'callRecordDetails':
                    this.path = DEFAULT_MAP[this.key][this.achievementDetailType];
                    this.exportUrl = performances_detail_new.export_call_record_details;
                    this.listUrl = performances_detail_new.call_record_detail;
                    break;
                case 'candidateViewDetails':
                    this.exportUrl = performances_detail_new.export_candidate_view_details;
                    this.listUrl = performances_detail_new.candidate_view_details;
                    break;
                case 'candidateUpdateDetails':
                    this.exportUrl = performances_detail_new.export_candidate_update_details;
                    this.listUrl = performances_detail_new.candidate_update_details;
                    break;
                case 'jobDetails':
                    this.exportUrl = performances_detail_new.export_job_update_details;
                    this.listUrl = performances_detail_new.export_job_details;
                    break;
                case 'orderDetails':
                    this.exportUrl = performances_detail_new.export_order_details;;
                    this.listUrl = performances_detail_new.order_details;;
                    break;
                case 'recommendationDetails':
                    this.exportUrl = performances_detail_new.export_recommendation_flow_details;
                    this.listUrl = performances_detail_new.recommendatoin_details;
                    break;
                case 'generalCoordinatorRecommendationDetails':
                    this.exportUrl = performances_detail_new.export_general_coordinator_recommendation_flow_details;
                    this.listUrl = performances_detail_new.general_coordinator_recommendatoin_details;
                    break;
                case 'trackingListDetails':
                    // this.exportUrl = performances_detail_new.export_candidate_details;
                    this.listUrl = performances_detail_new.tracking_list_detail;
                    break;
            }

            if(this.key == 'trackingListDetails') {
                this.path = DEFAULT_MAP[this.key];
            }else if(this.key == 'candidateViewDetails') {
                this.path = DEFAULT_MAP[this.key][this.CandidateViewDetailType];
            }else if(this.key == 'candidateUpdateDetails') {
                this.path = DEFAULT_MAP[this.key][this.CandidateUpdateDetailType];
            }else {
                this.path = DEFAULT_MAP[this.key][this.achievementDetailType];
            }
            // console.log(this.path)
            this.title = this.path + '：';
            this.dateRange = [moment(this.json.startDate).format('YYYY-MM-DD'), moment(this.json.endDate).format('YYYY-MM-DD')];

            this.getListData();
            if(this.pageType == 'teamData') {
                this.$nextTick(() => {
                    this.$refs.teamSelect && this.$refs.teamSelect.getHistoryTeamData(false);
                });
            }

            if(this.key == 'generalCoordinatorRecommendationDetails'){
                this.getGeneralCoordinatorCustomer();
            }
        },
        methods: {
            // handleUpdateItem(item){
            //     this.$emit('update-item',item);
            // },
            // 获取综合协调人的客户列表
            getGeneralCoordinatorCustomer(){
                performanceSetService.getGeneralCoordinatorCustomer()
                    .then(res =>{
                        // console.log(res)
                        this.clientOptions = res;
                    }).catch(err =>{
                        console.log(err)
                    }).finally(() => {
                    });
            },
            clientSelectFocus(){
                this.clientVisible = true;
            },
            clientSelectBlur(){
                this.clientVisible = false;
            },
            // 将团队名进行转换，返回的是数组
            transformTeam(val) {
                if(Object.prototype.toString.call(val) === '[object Array]') {
                    return val.join(',');
                }
                return val;
            },
            
            getListData() {
                this.listLoading = true;
                
                let dataJson = this.json;
                dataJson.start = (this.page - 1) * this.pageSize;
                dataJson.take = this.pageSize;
                // console.log(dataJson)
                if(_getQueryString('frompage') == 'daily') {
                    dataJson.isDaily = true;
                }
                if(this.key == 'candidateViewDetails'  || this.key == 'candidateUpdateDetails'){
                    _request({
                        method: 'POST',
                        url: this.listUrl,
                        data: dataJson
                    }).then(res =>{
                        this.dealRequestData(res);
                    }).catch(err =>{
                        this.dataFetched = true;
                        this.listLoading = false;
                    });
                }else {
                    let extra = {};
                    if(this.key != 'trackingListDetails') {
                        extra.achievementDetailType = dataJson.achievementDetailType;
                    }
                    dataJson = Object.assign({}, {
                        offset: dataJson.start,
                        pageSize: dataJson.take,
                        startDate: dataJson.startDate.replace(/\//g, '-'),
                        endDate: dataJson.endDate.replace(/\//g, '-'),
                        userIds: dataJson.userIds,
                        customerIds: this.clientValue ? [this.clientValue] : [],
                        // evaluateStatus: dataJson.evaluateStatus
                    }, extra)
                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: this.listUrl,
                        data: dataJson
                    }).then(res =>{
                        // this.dealRequestData(res);

                        this.$nextTick(() =>{
                            // 处理跳转页面后重新滚动到顶部,兼容200内滚动不自动滑动到顶部
                            // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                            const _scrollTop = document.querySelector(".performances-detail").scrollTop;
                            // if(_scrollTop > 200) {
                            if(_scrollTop > 100) {
                                // document.documentElement.scrollTop = 0;
                                // document.body.scrollTop = 0;
                                document.querySelector(".performances-detail").scrollTop = 0;
                            }
                        })

                        this.dealRequestData(res);

                    }).catch(err =>{
                        this.dataFetched = true;
                        this.listLoading = false;
                    });
                    // 处理跳转页面后重新滚动到顶部,兼容200内滚动不自动滑动到顶部
                    // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                    const _scrollTop = document.querySelector(".performances-detail").scrollTop;
                    // if(_scrollTop > 200) {
                    if(_scrollTop > 100) {
                        // document.documentElement.scrollTop = 0;
                        // document.body.scrollTop = 0;
                        document.querySelector(".performances-detail").scrollTop = 0;
                    }
                    
                    if(this.key == 'trackingListDetails') {
                        this.getChartData();
                    }
                }
            },                
            // 处理请求的列表数据
            dealRequestData(res) {
                let list;
                if(res.total){
                    this.totalCount = res.total;
                    // console.log( this.totalCount)
                    this.pagerTotalCount = res.total;
                    list = res.list || [];
                }else{
                    this.pagerTotalCount = res.count;
                    this.totalCount = res.count;
                    list = res.data || [];
                }
                
                let map = this._processColumnMap();
                list.unshift(map);
                if(list.length > 1){
                    if(this.key == 'recommendationDetails' && (this.achievementDetailType == 1 || this.achievementDetailType == 2 || this.achievementDetailType == 3)) {
                        this.totalCount = list[1].candidateCount || 0;
                    }

                    let ids = [];
                    list.forEach(item => {
                        Object.keys(item).forEach(key => {
                            if(key !== 'candidateId' && isUndefined(map[key])){
                                delete item[key];
                            }
                        })
                        if(item.candidateId) {
                            ids.push(item.candidateId);
                        }
                        if(item.resumeId) {
                            ids.push(item.resumeId);
                        }
                    })
                    if('candidateId' in list[1]) {
                        resumeMergeService.getCandidateRedirectIds(ids)
                            .then(res =>{
                                if(res) {
                                    list.forEach(item => {
                                        if(item.candidateId && res[item.candidateId]) {
                                            item.redirectId = res[item.candidateId];
                                        }
                                        if(item.resumeId && res[item.resumeId]) {
                                            item.redirectId = res[item.resumeId];
                                        }
                                    })
                                }
                                this.listData = list;
                                this.listData = this.listData.map(el => {
                                    return {
                                        ...el,
                                        resumeListItem: el
                                    }
                                })
                                this.listLoading = false;
                                this.dataFetched = true;
                            }).catch((err) => {
                                this.listData = list;
                                this.listData = this.listData.map(el => {
                                    return {
                                        ...el,
                                        resumeListItem: el
                                    }
                                })
                                this.listLoading = false;
                                this.dataFetched = true;
                            })
                    }else {
                        this.listData = list;
                        this.listData = this.listData.map(el => {
                            return {
                                ...el,
                                resumeListItem: el
                            }
                        })
                        // console.log(this.listData)
                        this.resumeListItem = 
                        this.this.dataFetched = true;
                        this.listLoading = false;
                    }
                }else {
                    this.listData = [];
                    this.dataFetched = true;
                    this.listLoading = false;
                }

            },
            _processColumnMap() {
                let map = Object.assign({}, performancesMap[this.key]);
                let key = this.key;
                console.log(key)
                // 特殊需求：关于面试的都需要面试时间
                // if((key === "conversionStatisticsRecommendationDetails" || key == "performanceStatisticsRecommendationDetails" ) && this.json.orderBy === "arrangeInterview"){
                //     map.interviewTime = "面试时间 ";
                //     map.created = "反馈时间";
                // }
                if((key === 'recommendationDetails' || key === 'generalCoordinatorRecommendationDetails') && (this.achievementDetailType == 1 || this.achievementDetailType == 4 || this.achievementDetailType == 12)) {
                    map.operationTime = '最近处理时间';
                    map.status = '当前推荐状态'
                }
                return map;
            },
            _getRootClassBind() {
                return this.key == 'recommendatoinObsoletedDetails' || this.key == 'interviewObsoletedDetails' ? `obsoleted-wrapper` : ''
            },
            handleExport(type, item) {
                if(this.isLoadingExport || this.isLoadingExportTable) return;
                let data = this.json;
                let baseUrl = this.exportUrl;
                let paramObj = {
                    startDate: data.startDate.replace(/\//g, '-'),
                    endDate: data.endDate.replace(/\//g, '-'),
                    userIds: data.userIds
                };
                if(this.key == 'callRecordDetails' && type == 'list') {
                    baseUrl = performances_detail_new.export_call_statistics_async;
                }

                if(this.key == 'trackingListDetails') {
                    baseUrl = performances_detail_new.export_tracking_list_details;
                }

                if(this.key != 'trackingListDetails') {
                    paramObj.achievementDetailType = data.achievementDetailType;
                }

                if (type == 'list') {
                    this.isLoadingExportTable = true;
                } else {
                    this.isLoadingExport = true;
                }
                if(this.key == 'candidateViewDetails'  || this.key == 'candidateUpdateDetails'){
                    if(this.key == 'candidateViewDetails') {
                        paramObj.CandidateViewDetailType = this.CandidateViewDetailType;
                    }
                    if(this.key == 'candidateUpdateDetails') {
                        paramObj.CandidateUpdateDetailType = this.CandidateUpdateDetailType;
                    }
                    _request({
                        url: baseUrl,
                        method: 'POST',
                        data: paramObj
                    }).then(res => {
                        this.dealExportUrl(res, type);
                    }).finally(() => {
                        this.isLoadingExport = false;
                        this.isLoadingExportTable = false;
                    })
                    // $.post(baseUrl, paramObj).then(res=>{
                    //     if(res.succeeded) {
                    //         this.dealExportUrl(res, type);
                    //         this.isLoadingExport = false;
                    //         this.isLoadingExportTable = false;
                    //     }else {
                    //         shortTips(res.message);
                    //         this.isLoadingExport = false;
                    //         this.isLoadingExportTable = false;
                    //     }
                    // });
                }else{
                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: baseUrl,
                        responseType: 'blob',
                        data: paramObj
                    }).then(res =>{
                        // java接口这边采用的是文件流返回，需要写入
                        downloadFile(res);
                    }).catch((err) => {
                        shortTips(err.message || '系统异常');
                    }).finally(() => {
                        this.isLoadingExport = false;
                        this.isLoadingExportTable = false;
                    });
                }
            },
            // 处理返回的url下载文件
            dealExportUrl(res, type) {
                let tempWindow = window.open('');
                res.fileUrl && (tempWindow.location = res.fileUrl);
            },
            handleSwitchExport(type, item) {
                if(type === 'enter') {
                    this.exportFold = false;
                }else {
                    this.exportFold = true;
                }
            },
            checkAdmin() {
                _request({
                    url: user.is_admin,
                    method: 'POST'
                }).then(res =>{
                    this.isAdmin = res.isAdmin;
                })
            },
            handleCandidate(item){
                lbdTrack.track('performancesDetailViewCandidate',{candidateId:item.candidateId,key:this.key});
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.pagerTotalCount/this.pageSize);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.page = currentPager;
                    this.getListData();
                }
            },
            handleCurrentChange(val){
                this.page = val;
                this.getListData();
            },
            handleSizeChange(val){
                this.page = 1;
                this.pageSize = val;
                this.getListData();
            },
            evaluateStatusChange(value) {
                console.log(value);
                this.json.evaluateStatus = value;
                this.getListData();
            },
            handleDate(value) {
                this.json.startDate = moment(value[0]).format('YYYY-MM-DD');
                this.json.endDate = moment(value[1]).format('YYYY-MM-DD');
                sessionStorage.setItem('perpormancesDetailData', JSON.stringify(this.json));

                if(this.pageType == 'teamData') {
                    this.$nextTick(() => {
                        this.$refs.teamSelect && this.$refs.teamSelect.getHistoryTeamData(true);
                    });
                } else {
                    this.handleCurrentChange(1);
                }
            },
            handleFocusDate() {
                this.isFocusDate = true;
            },
            handleBlurDate() {
                this.isFocusDate = false;
            },
            // 选择成员
            employeeSelect(userIds, text) {
                this.json.userIds = userIds;
                this.selectedMembersText = text;
                this.teamMemberPopoverVisible = false;
                this.page = 1;
                this.getListData();
            },
            // 获取饼图数据 
            getChartData() {
                let dataJson = this.json;
                dataJson.start = (this.page - 1) * this.pageSize;
                dataJson.take = this.pageSize;
                const params = {
                    achievementDetailType: null,
                    offset: dataJson.start,
                    pageSize: dataJson.take,
                    startDate: dataJson.startDate.replace(/\//g, '-'),
                    endDate: dataJson.endDate.replace(/\//g, '-'),
                    userIds: dataJson.userIds,
                };  
                this.loadChart = true;
                performanceSetService.trackingListDistribution(params)
                .then(res =>{
                    this.$refs.trackingListChart.setData(res);
                }).catch(err =>{
                    console.log(err)
                }).finally(() => {
                    this.loadChart = false;
                });
            },
        }
    }
</script>


<style lang="scss">
.performances-detail {
    .select-client {
        .el-input__inner {
            &::placeholder {
                color: #606266 !important;
            }
            &::-webkit-input-placeholder {
                color: #606266 !important;
            }
            &::-moz-input-placeholder {
                color: #606266 !important;
            }
            &::-ms-input-placeholder {
                color: #606266 !important;
            }
        }
    }

    
}
</style>
<style lang="scss" scope>
    @import "./index.scss";

    .performances-detail {
        background-color: #f6f6f6;
        // padding-top: 30px;
        // padding-bottom: 30px;
        // margin: 0;
        padding: 20px;
        width: 100%;
        // min-height: 830px;
        height: 100%;
        overflow-y: auto;

        &-content {
            // width: 1200px;
            // margin: 0 auto;
        }

        .item-name {
            a {
                color: #666666;
                &:hover {
                    color: #38bc9c;
                }
            }
        }

        .item-job {
            max-width: 160px;
        }

        .item-recommend-time {
            // max-width: 190px;
            max-width: 260px;
        }
        .item-recommend-status{
            max-width: 220px;
        }
        .item-recommend-id {
            max-width: 330px;
        }

        .item-interview {
            max-width: 140px;
        }

        .item:last-child {
            max-width: 132px;
        }
        .item.item-listen-inquiry {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .item-listen-record{
            max-width: 280px;
        }
        .item-listen{
            min-width: 100px;
        }
        
        .order-wrapper {
            .cursor-pointer{
                cursor: pointer;
            }
            .color-warn{
                color: #FF6564;
            }
        }

        .order-wrapper-empty {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            line-height: 100px;
            margin: 0;
        }
        .evaluate-select-wrap {
            display: inline-block;
            margin-right: 16px;
            .el-select {
                .el-input__inner {
                    height: 32px;
                }
                /* 下面设置右侧按钮居中 */
                .el-input__icon {
                    line-height: inherit;
                }
                .el-input__suffix-inner {
                    display: inline-block;
                }
            }
            &:hover {
                .el-input__inner {
                    background-color: #38BC9D;
                    color: #fff;
                    &::placeholder{
                        color: #fff;
                    }
                }
                .el-input__icon {
                    color: #fff;
                }
                .el-input__suffix-inner {
                    color: #fff;
                }
            }
        }
        .date-wrap {
            display: inline-block;
            vertical-align: top;
            position: relative;
            cursor: pointer;
            // width: 190px;
            width: 260px;
            margin: 0 auto 16px;
            &:hover{
                .el-date-editor--daterange.el-input__inner{
                    border-color:#38BC9D;
                    background: #38BC9D;
                    .el-range-input{
                        background: #38BC9D;
                        color: #fff;
                    }
                    .el-range-separator{
                        color: #fff;
                    }
                }
            }
            .el-range-editor.is-active{
                background: #38BC9D;
                .el-range-input{
                    background: #38BC9D;
                    color: #fff;
                }
                .el-range-separator{
                    color: #fff;
                }
            }
            &.triangle-drop-up{
                &::after{
                    border-bottom: 12px solid #fff;
                }
            }
            .el-date-editor--daterange.el-input__inner{
                // width: 190px;
                width: 260px;
                // border-radius: 16px;
            }
            .el-date-editor .el-range__icon{
                // display: none;
            }

            .el-date-editor .el-range__close-icon{
                display: none;
            }
        }
        .triangle-drop{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
            }
            &-down{
                &:after{
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-top: 12px solid #fff;
                    }
                }

            }
            &-up{
                &:after{
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }
        .sub-title{
            // width: 100%;
            text-align: center;
            margin-right: 20px;
            .client-select-wrap {
                display: inline-block;
                position: relative;
                margin-left: 20px;
                vertical-align: top;
                .el-select.select-client {
                    &:hover {
                        .el-input__inner{
                            background: #38BC9D;
                            border-color: #38BC9D;
                            color: #fff;
                            &::placeholder {
                                color: #fff !important;
                            }
                            &::-webkit-input-placeholder {
                                color: #fff !important;
                            }
                            &::-moz-input-placeholder {
                                color: #fff !important;
                            }
                            &::-ms-input-placeholder {
                                color: #fff !important;
                            }
                        }
                    }
                    input.el-input__inner {
                        // width: 118px;
                        width: 190px;
                        height: 32px;
                        // border-radius: 16px;
                    }
                    .el-icon-arrow-up:before {
                        display: none;
                    }
                }
            }
        }
        .employee-select-wrap{
            display: inline-block;
            position: relative;
            margin-left: 20px;
            vertical-align: top;

            .employee-select-text {
                display: inline-block;
                width: 116px;
                height: 32px;
                line-height: 32px;
                border: 1px solid #d3d3d3;
                // border-radius: 16px;
                border-radius: 4px;
                padding: 0 36px 0 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: top;
            }

            &:hover{
                .employee-select-text{
                    background: #38BC9D;
                    border-color: #38BC9D;
                    color: #fff;
                }
            }
            &.triangle-drop-up{
                &::after{
                    border-bottom: 12px solid #fff;
                    margin-top: -3px;
                }
                .employee-select-text{
                    background: #38BC9D;
                    border-color: #38BC9D;
                    color: #fff;
                }
            }
        }
        .delivery-target-top{
            position: relative;
        }
        .export{
            position: absolute;
            right: 0;
        }
    }
    @media screen and (max-width: 1366px) {
        .performances-detail {
            min-height: 661px;
        }
    }

    .work-table-footer {
        // margin: 20px 35px 0 0;
        margin: 35px 35px 0 0;
    }
    .performance-data-range.el-date-range-picker {
        .popper__arrow {
            left: 50% !important;
        }
    }
    .el-button.el-button--primary,.performances-detail-el-export,.performances-detail-el-export.is-loading{
        background-color: #38bc9c;
        border-color: #38bc9c;
        font-size: 14px;
        display: block;
        // float: right;
        // margin-right: 28px;
        span {
            color: white;
        }
    }
    .performances-detail-el-export.is-loading{
        position: static!important;
    }
    .performances-detail-export{
        font-size: 0;
        background-color: #fff;
        width: auto;
    }
    .performances-detail-export-new{
        // width: 40%;
        // height: auto;
        // position: absolute;
        // right: 0;
        // top: 40px;
        display: flex;
    }
    .el-select-dropdown__item.client-select-item {
        // width: 350px;
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>