var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "performances-detail", class: _vm._getRootClassBind() },
    [
      _c("div", { staticClass: "performances-detail-content" }, [
        _c(
          "div",
          { staticClass: "performances-detail-main" },
          [
            _c("div", { staticClass: "performances-detail-title" }, [
              _c("div", { staticClass: "table-title" }, [
                _c("i", { domProps: { textContent: _vm._s(_vm.title) } }),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.totalCount) },
                }),
              ]),
              _c("div", { staticClass: "performances-detail-option" }, [
                _c("div", { staticClass: "sub-title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "date-wrap triangle-drop",
                      class: _vm.isFocusDate
                        ? "triangle-drop-up"
                        : "triangle-drop-down",
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "start-placeholder": "开始日期",
                          "range-separator": "-",
                          "end-placeholder": "结束日期",
                          size: "small",
                          editable: false,
                          align: "center",
                          format: "yyyyMMdd",
                          clearable: false,
                          "picker-options": _vm.expireTimeOption,
                          "popper-class": "performance-data-range",
                        },
                        on: {
                          change: _vm.handleDate,
                          focus: _vm.handleFocusDate,
                          blur: _vm.handleBlurDate,
                        },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.pageType == "teamData"
                    ? _c(
                        "div",
                        {
                          staticClass: "employee-select-wrap triangle-drop",
                          class: _vm.teamMemberPopoverVisible
                            ? "triangle-drop-up"
                            : "triangle-drop-down",
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class":
                                  "team-member-popover delivery-team-member-popover",
                                placement: "bottom-end",
                                width: "700",
                                trigger: "click",
                              },
                              model: {
                                value: _vm.teamMemberPopoverVisible,
                                callback: function ($$v) {
                                  _vm.teamMemberPopoverVisible = $$v
                                },
                                expression: "teamMemberPopoverVisible",
                              },
                            },
                            [
                              _c("team-member-select", {
                                ref: "teamSelect",
                                attrs: {
                                  isAllMembers: true,
                                  isShowDimission: false,
                                  date: [_vm.json.startDate, _vm.json.endDate],
                                  isHistoryData: true,
                                  visible: _vm.teamMemberPopoverVisible,
                                  selected: _vm.json.userIds,
                                },
                                on: { "item-select-cb": _vm.employeeSelect },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "employee-select-text",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [_vm._v(_vm._s(_vm.selectedMembersText))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.achievementDetailType == 12 ||
                  _vm.achievementDetailType == 13 ||
                  _vm.achievementDetailType == 14
                    ? _c(
                        "div",
                        { staticClass: "client-select-wrap" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "select-client triangle-drop",
                              class: _vm.clientVisible
                                ? "triangle-drop-up"
                                : "triangle-drop-down",
                              attrs: { placeholder: "请选择客户" },
                              on: {
                                change: _vm.getListData,
                                focus: _vm.clientSelectFocus,
                                blur: _vm.clientSelectBlur,
                              },
                              model: {
                                value: _vm.clientValue,
                                callback: function ($$v) {
                                  _vm.clientValue = $$v
                                },
                                expression: "clientValue",
                              },
                            },
                            _vm._l(_vm.clientOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                staticClass: "client-select-item",
                                attrs: {
                                  title: item.name,
                                  label: item.name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.key == "callRecordDetails"
                  ? _c(
                      "div",
                      {
                        staticClass: "performances-detail-export-new",
                        on: {
                          mouseover: function ($event) {
                            return _vm.handleSwitchExport("enter")
                          },
                          mouseout: function ($event) {
                            return _vm.handleSwitchExport("out")
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "performances-detail-el-export",
                            attrs: {
                              type: "primary",
                              disabled: _vm.isLoadingExport,
                              loading: _vm.isLoadingExport,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleExport("detail")
                              },
                            },
                          },
                          [_vm._v("导出明细")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "performances-detail-el-export",
                            attrs: {
                              type: "primary",
                              disabled: _vm.isLoadingExportTable,
                              loading: _vm.isLoadingExportTable,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleExport("list")
                              },
                            },
                          },
                          [_vm._v("导出统计表")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "performances-detail-export-new" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "performances-detail-el-export",
                            attrs: {
                              type: "primary",
                              disabled: _vm.isLoadingExport,
                              loading: _vm.isLoadingExport,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleExport("detail")
                              },
                            },
                          },
                          [_vm._v("导出明细")]
                        ),
                      ],
                      1
                    ),
              ]),
            ]),
            _vm.key == "trackingListDetails"
              ? _c("tracking-list-chart", {
                  ref: "trackingListChart",
                  attrs: { loading: _vm.loadChart },
                })
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-container table-content",
              },
              [
                _c("div", { staticClass: "order-wrapper" }, [
                  _vm.dataFetched && _vm.listData.length == 0
                    ? _c("p", { staticClass: "order-wrapper-empty" }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                  _c(
                    "ul",
                    { staticClass: "order visible order-full" },
                    _vm._l(_vm.listData, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: Math.random().toString() + index,
                          staticClass: "order-item",
                        },
                        [
                          _vm.key == "candidateDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.realName },
                                  domProps: {
                                    textContent: _vm._s(item.realName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.createTime },
                                  domProps: {
                                    textContent: _vm._s(item.createTime),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  domProps: {
                                    textContent: _vm._s(item.createType),
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "item",
                                    attrs: { title: item.candidateName },
                                  },
                                  [
                                    !item.detailUrl && item.candidateId
                                      ? _c(
                                          "a",
                                          _vm._b(
                                            {
                                              attrs: {
                                                target: "_blank",
                                                title: item.candidateName,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            },
                                            "a",
                                            {
                                              href: `#/candidateDetail/${
                                                item.redirectId
                                                  ? item.redirectId
                                                  : item.candidateId
                                              }`,
                                            },
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                    !item.detailUrl && !item.candidateId
                                      ? _c("span", {
                                          attrs: { title: item.candidateName },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.candidateName
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.title },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.company },
                                  domProps: {
                                    textContent: _vm._s(item.company),
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.key == "candidateInquiryLogDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.realName },
                                  domProps: {
                                    textContent: _vm._s(item.realName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.createTime },
                                  domProps: {
                                    textContent: _vm._s(item.createTime),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  domProps: {
                                    textContent: _vm._s(item.isValid),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  domProps: {
                                    textContent: _vm._s(item.sourceFrom),
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "item",
                                    attrs: { title: item.candidateName },
                                  },
                                  [
                                    item.detailUrl
                                      ? _c("anchor", {
                                          attrs: {
                                            "enable-external": true,
                                            href: item.detailUrl,
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.candidateName
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    !item.detailUrl && item.candidateId
                                      ? _c(
                                          "a",
                                          _vm._b(
                                            {
                                              attrs: {
                                                target: "_blank",
                                                title: item.candidateName,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            },
                                            "a",
                                            {
                                              href: `#/candidateDetail/${
                                                item.redirectId
                                                  ? item.redirectId
                                                  : item.candidateId
                                              }`,
                                            },
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                    !item.detailUrl && !item.candidateId
                                      ? _c("span", {
                                          attrs: { title: item.candidateName },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.candidateName
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.title },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.company },
                                  domProps: {
                                    textContent: _vm._s(item.company),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.content },
                                  domProps: {
                                    textContent: _vm._s(item.content),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.recommendationInfo },
                                  domProps: {
                                    textContent: _vm._s(
                                      item.recommendationInfo
                                    ),
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.key == "callRecordDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item item-listen",
                                  attrs: { title: item.realName },
                                  domProps: {
                                    textContent: _vm._s(item.realName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-listen",
                                  attrs: { title: item.callStartTime },
                                  domProps: {
                                    textContent: _vm._s(item.callStartTime),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-listen",
                                  domProps: {
                                    textContent: _vm._s(item.isValidCall),
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "item item-listen-record" },
                                  [
                                    item.url && item.url !== "通话录音"
                                      ? _c("record-listen", {
                                          attrs: {
                                            "audio-url": item.url,
                                            duration: item.duration,
                                          },
                                        })
                                      : _vm._e(),
                                    item.url && item.url === "通话录音"
                                      ? _c(
                                          "span",
                                          {
                                            domProps: {
                                              textContent: _vm._s(item.url),
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    title: item.candidateName,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.candidateName
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCandidate(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "a",
                                                {
                                                  href: `#/candidateDetail/${
                                                    item.redirectId
                                                      ? item.redirectId
                                                      : item.candidateId
                                                  }`,
                                                },
                                                false
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !item.url && item.hasOwnProperty("url")
                                      ? _c("span")
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("span", {
                                  staticClass: "item item-listen",
                                  domProps: {
                                    textContent: _vm._s(item.isCandidate),
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "item item-listen item-listen-long",
                                  },
                                  [
                                    item.isCandidate == "是"
                                      ? _c(
                                          "a",
                                          _vm._b(
                                            {
                                              attrs: {
                                                target: "_blank",
                                                title: item.candidateName,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            },
                                            "a",
                                            {
                                              href: `#/candidateDetail/${
                                                item.redirectId
                                                  ? item.redirectId
                                                  : item.candidateId
                                              }`,
                                            },
                                            false
                                          )
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              cursor: "not-allowed",
                                            },
                                            attrs: {
                                              title: item.candidateName,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.candidateName))]
                                        ),
                                  ]
                                ),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.title },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.company },
                                  domProps: {
                                    textContent: _vm._s(item.company),
                                  },
                                }),
                                _c("span", {
                                  staticClass:
                                    "item item-listen item-listen-inquiry",
                                  attrs: { title: item.inquiryLogContent },
                                  domProps: {
                                    textContent: _vm._s(item.inquiryLogContent),
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.key == "jobDetails"
                            ? [
                                _vm.achievementDetailType == 1
                                  ? [
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.realName },
                                        domProps: {
                                          textContent: _vm._s(item.realName),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        domProps: {
                                          textContent: _vm._s(
                                            item.jobCreateType
                                          ),
                                        },
                                      }),
                                      _c("span", { staticClass: "item" }, [
                                        item.jobId
                                          ? _c(
                                              "a",
                                              {
                                                staticStyle: { color: "#666" },
                                                attrs: {
                                                  href: item.jobId
                                                    ? "/#/Job/" + item.jobId
                                                    : "javascript:void(0)",
                                                  title: item.jobName,
                                                  target: item.jobId
                                                    ? "_blank"
                                                    : "_self",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.jobName))]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(item.jobName)),
                                            ]),
                                      ]),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.customerName },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.customerName
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser
                                        ? [
                                            _c("span", {
                                              staticClass: "item",
                                              attrs: {
                                                title:
                                                  item.customerDomain || "-",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.customerDomain || "-"
                                                ),
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.createTime },
                                        domProps: {
                                          textContent: _vm._s(item.createTime),
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm.achievementDetailType == 2
                                  ? [
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.realName },
                                        domProps: {
                                          textContent: _vm._s(item.realName),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.updateTime },
                                        domProps: {
                                          textContent: _vm._s(item.updateTime),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        domProps: {
                                          textContent: _vm._s(item.updateType),
                                        },
                                      }),
                                      _c("span", { staticClass: "item" }, [
                                        item.jobId
                                          ? _c(
                                              "a",
                                              {
                                                staticStyle: { color: "#666" },
                                                attrs: {
                                                  href: item.jobId
                                                    ? "/#/Job/" + item.jobId
                                                    : "javascript:void(0)",
                                                  title: item.jobName,
                                                  target: item.jobId
                                                    ? "_blank"
                                                    : "_self",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.jobName))]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(item.jobName)),
                                            ]),
                                      ]),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.updateContent },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.updateContent
                                          ),
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm.key == "orderDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  attrs: { title: item.realName },
                                  domProps: {
                                    textContent: _vm._s(item.realName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  attrs: { title: item.orderTime },
                                  domProps: {
                                    textContent: _vm._s(item.orderTime),
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "item item-order-detail" },
                                  [
                                    item.jobId
                                      ? _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#666" },
                                            attrs: {
                                              href: item.jobId
                                                ? "/#/Job/" + item.jobId
                                                : "javascript:void(0)",
                                              title: item.jobName,
                                              target: item.jobId
                                                ? "_blank"
                                                : "_self",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.jobName))]
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(item.jobName)),
                                        ]),
                                  ]
                                ),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  domProps: {
                                    textContent: _vm._s(item.recruitingCount),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  attrs: { title: item.customerName },
                                  domProps: {
                                    textContent: _vm._s(item.customerName),
                                  },
                                }),
                                _vm.isCFUser
                                  ? [
                                      _c("span", {
                                        staticClass: "item item-order-detail",
                                        attrs: {
                                          title: item.customerDomain || "-",
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.customerDomain || "-"
                                          ),
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  attrs: { title: item.jobCreator },
                                  domProps: {
                                    textContent: _vm._s(item.jobCreator),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  domProps: {
                                    textContent: _vm._s(
                                      item.recommendationCount
                                    ),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  domProps: {
                                    textContent: _vm._s(item.acceptCount),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  domProps: {
                                    textContent: _vm._s(item.interviewCount),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item item-order-detail",
                                  domProps: {
                                    textContent: _vm._s(item.offerCount),
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.key == "candidateViewDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.realName },
                                  domProps: {
                                    textContent: _vm._s(item.realName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  domProps: {
                                    textContent: _vm._s(item.viewCount),
                                  },
                                }),
                                index == 0
                                  ? _c("span", {
                                      staticClass: "item",
                                      domProps: {
                                        textContent: _vm._s(item.candidateName),
                                      },
                                    })
                                  : _vm._e(),
                                index != 0
                                  ? _c(
                                      "span",
                                      { staticClass: "item" },
                                      [
                                        item.detailUrl
                                          ? _c("anchor", {
                                              attrs: {
                                                "enable-external": true,
                                                href: item.detailUrl,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        !item.detailUrl && item.candidateId
                                          ? _c(
                                              "a",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    title: item.candidateName,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.candidateName
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCandidate(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "a",
                                                {
                                                  href: `#/candidateDetail/${
                                                    item.redirectId
                                                      ? item.redirectId
                                                      : item.candidateId
                                                  }`,
                                                },
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                        !item.detailUrl && !item.candidateId
                                          ? _c("span", {
                                              attrs: {
                                                title: item.candidateName,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.title },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.company },
                                  domProps: {
                                    textContent: _vm._s(item.company),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  domProps: {
                                    textContent: _vm._s(item.viewType),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.inqueryLog },
                                  domProps: {
                                    textContent: _vm._s(item.inqueryLog),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.callLog },
                                  domProps: {
                                    textContent: _vm._s(item.callLog),
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.key == "candidateUpdateDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.realName },
                                  domProps: {
                                    textContent: _vm._s(item.realName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.updateTime },
                                  domProps: {
                                    textContent: _vm._s(item.updateTime),
                                  },
                                }),
                                index == 0
                                  ? _c("span", {
                                      staticClass: "item",
                                      domProps: {
                                        textContent: _vm._s(item.candidateName),
                                      },
                                    })
                                  : _vm._e(),
                                index != 0
                                  ? _c(
                                      "span",
                                      { staticClass: "item" },
                                      [
                                        item.detailUrl
                                          ? _c("anchor", {
                                              attrs: {
                                                "enable-external": true,
                                                href: item.detailUrl,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        !item.detailUrl && item.candidateId
                                          ? _c(
                                              "a",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    title: item.candidateName,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.candidateName
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCandidate(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "a",
                                                {
                                                  href: `#/candidateDetail/${
                                                    item.redirectId
                                                      ? item.redirectId
                                                      : item.candidateId
                                                  }`,
                                                },
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                        !item.detailUrl && !item.candidateId
                                          ? _c("span", {
                                              attrs: {
                                                title: item.candidateName,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.candidateName
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.title },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.company },
                                  domProps: {
                                    textContent: _vm._s(item.company),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  domProps: {
                                    textContent: _vm._s(item.updateType),
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm.key == "recommendationDetails" ||
                          _vm.key == "generalCoordinatorRecommendationDetails"
                            ? [
                                _vm.achievementDetailType == 1 ||
                                _vm.achievementDetailType == 2 ||
                                _vm.achievementDetailType == 3
                                  ? [
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.realName },
                                        domProps: {
                                          textContent: _vm._s(item.realName),
                                        },
                                      }),
                                      _vm.key == "recommendationDetails"
                                        ? _c("span", {
                                            staticClass:
                                              "item item-recommend-id",
                                            attrs: {
                                              title: item.recommendationId,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationId
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: { title: item.recommendTime },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.recommendTime
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: { title: item.operationTime },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.operationTime
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: { title: item.status },
                                        domProps: {
                                          textContent: _vm._s(item.status),
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "item",
                                          attrs: { title: item.candidateName },
                                        },
                                        [
                                          item.detailUrl
                                            ? _c("anchor", {
                                                attrs: {
                                                  "enable-external": true,
                                                  href: item.detailUrl,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateName
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          !item.detailUrl && item.candidateId
                                            ? _c(
                                                "a",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      title: item.candidateName,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.candidateName
                                                      ),
                                                    },
                                                  },
                                                  "a",
                                                  {
                                                    href: `#/candidateDetail/${
                                                      item.redirectId
                                                        ? item.redirectId
                                                        : item.candidateId
                                                    }`,
                                                  },
                                                  false
                                                )
                                              )
                                            : _vm._e(),
                                          !item.detailUrl && !item.candidateId
                                            ? _c("span", {
                                                attrs: {
                                                  title: item.candidateName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateName
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.company },
                                        domProps: {
                                          textContent: _vm._s(item.company),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.title },
                                        domProps: {
                                          textContent: _vm._s(item.title),
                                        },
                                      }),
                                      _vm.key == "recommendationDetails"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.jobSource },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.jobSource
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.customerName },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.customerName
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser
                                        ? [
                                            _vm.key == "recommendationDetails"
                                              ? _c("span", {
                                                  staticClass:
                                                    "item item-order-detail",
                                                  attrs: {
                                                    title:
                                                      item.customerDomain ||
                                                      "-",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.customerDomain || "-"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.department },
                                        domProps: {
                                          textContent: _vm._s(item.department),
                                        },
                                      }),
                                      _c("span", { staticClass: "item" }, [
                                        item.jobId
                                          ? _c(
                                              "a",
                                              {
                                                staticStyle: { color: "#666" },
                                                attrs: {
                                                  href: item.jobId
                                                    ? "/#/Job/" + item.jobId
                                                    : "javascript:;",
                                                  title: item.jobName,
                                                  target: item.jobId
                                                    ? "_blank"
                                                    : "_self",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.jobName))]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(item.jobName)),
                                            ]),
                                      ]),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.acceptUser },
                                        domProps: {
                                          textContent: _vm._s(item.acceptUser),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: {
                                          title: item.candidateSourceChannel,
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.candidateSourceChannel
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: {
                                              title:
                                                item.businessClassificationName,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.businessClassificationName ||
                                                  "-"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      index == 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "item",
                                              attrs: { title: "我的评价" },
                                            },
                                            [_vm._v("我的评价")]
                                          )
                                        : _vm._e(),
                                      index != 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _vm._v(_vm._s(item.evaluateStatus)),
                                          ])
                                        : _vm._e(),
                                      index == 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "item",
                                              attrs: { title: "操作" },
                                            },
                                            [_vm._v("操作")]
                                          )
                                        : _vm._e(),
                                      index != 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: `/#/Job/${item.jobId}/recommendation/${item.recommendationId}?recommendDate=${item.recommendTime}`,
                                                  target: "_blank",
                                                },
                                              },
                                              [_vm._v("查看")]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm.achievementDetailType == 4 ||
                                _vm.achievementDetailType == 5 ||
                                _vm.achievementDetailType == 6 ||
                                _vm.achievementDetailType == 12
                                  ? [
                                      _c("span", {
                                        staticClass: "item item-job",
                                        attrs: { title: item.realName },
                                        domProps: {
                                          textContent: _vm._s(item.realName),
                                        },
                                      }),
                                      _vm.key == "recommendationDetails"
                                        ? _c("span", {
                                            staticClass:
                                              "item item-recommend-id",
                                            attrs: {
                                              title: item.recommendationId,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationId
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: { title: item.recommendTime },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.recommendTime
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: { title: item.operationTime },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.operationTime
                                          ),
                                        },
                                      }),
                                      index == 0 &&
                                      _vm.achievementDetailType == 5
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-interview",
                                            },
                                            [_vm._v("推荐主城市")]
                                          )
                                        : _vm._e(),
                                      index != 0 &&
                                      _vm.achievementDetailType == 5
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-recommend-time",
                                              attrs: { title: item.location },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.location?.length > 0
                                                    ? item.location.join()
                                                    : "-"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      index == 0 &&
                                      _vm.achievementDetailType == 5
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-interview",
                                            },
                                            [_vm._v("推荐其他城市")]
                                          )
                                        : _vm._e(),
                                      index != 0 &&
                                      _vm.achievementDetailType == 5
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-recommend-time",
                                              attrs: { title: item.locations },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.locations?.length > 0
                                                    ? item.locations.join()
                                                    : "-"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass:
                                          "item item-recommend-status",
                                        attrs: { title: item.status },
                                        domProps: {
                                          textContent: _vm._s(item.status),
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "item",
                                          attrs: { title: item.candidateName },
                                        },
                                        [
                                          item.detailUrl
                                            ? _c("anchor", {
                                                attrs: {
                                                  "enable-external": true,
                                                  href: item.detailUrl,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateName
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          !item.detailUrl && item.candidateId
                                            ? _c(
                                                "a",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      title: item.candidateName,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.candidateName
                                                      ),
                                                    },
                                                  },
                                                  "a",
                                                  {
                                                    href: `#/candidateDetail/${
                                                      item.redirectId
                                                        ? item.redirectId
                                                        : item.candidateId
                                                    }`,
                                                  },
                                                  false
                                                )
                                              )
                                            : _vm._e(),
                                          !item.detailUrl && !item.candidateId
                                            ? _c("span", {
                                                attrs: {
                                                  title: item.candidateName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateName
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.company },
                                        domProps: {
                                          textContent: _vm._s(item.company),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.title },
                                        domProps: {
                                          textContent: _vm._s(item.title),
                                        },
                                      }),
                                      _vm.key == "recommendationDetails"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.jobSource },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.jobSource
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.candidateCreator },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.candidateCreator
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: { title: item.candidateCreated },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.candidateCreated
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item item-job",
                                        attrs: { title: item.customerName },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.customerName
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser
                                        ? [
                                            _vm.key == "recommendationDetails"
                                              ? _c("span", {
                                                  staticClass:
                                                    "item item-order-detail",
                                                  attrs: {
                                                    title:
                                                      item.customerDomain ||
                                                      "-",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.customerDomain || "-"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm.pageType == "teamData"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.department },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.department
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "item item-job",
                                          attrs: { title: item.jobName },
                                        },
                                        [
                                          item.jobId
                                            ? _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    color: "#666",
                                                  },
                                                  attrs: {
                                                    href: item.jobId
                                                      ? "/#/Job/" + item.jobId
                                                      : "javascript:;;;",
                                                    title: item.jobName,
                                                    target: item.jobId
                                                      ? "_blank"
                                                      : "_self",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.jobName))]
                                              )
                                            : _c("span", [
                                                _vm._v(_vm._s(item.jobName)),
                                              ]),
                                        ]
                                      ),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.acceptUser },
                                        domProps: {
                                          textContent: _vm._s(item.acceptUser),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: {
                                          title: item.candidateSourceChannel,
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.candidateSourceChannel
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser && _vm.pageType == "teamData"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: {
                                              title:
                                                item.businessClassificationName,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.businessClassificationName ||
                                                  "-"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.pageType == "teamData"
                                        ? [
                                            index == 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      title: "我的评价",
                                                    },
                                                  },
                                                  [_vm._v("我的评价")]
                                                )
                                              : _vm._e(),
                                            index != 0
                                              ? _c(
                                                  "span",
                                                  { staticClass: "item" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.evaluateStatus
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm.pageType == "teamData"
                                        ? [
                                            index == 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "item",
                                                    attrs: { title: "操作" },
                                                  },
                                                  [_vm._v("操作")]
                                                )
                                              : _vm._e(),
                                            index != 0
                                              ? _c(
                                                  "span",
                                                  { staticClass: "item" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `/#/Job/${item.jobId}/recommendation/${item.recommendationId}?recommendDate=${item.recommendTime}`,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [_vm._v("查看")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm.achievementDetailType == 7 ||
                                _vm.achievementDetailType == 8 ||
                                _vm.achievementDetailType == 9 ||
                                _vm.achievementDetailType == 13
                                  ? [
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.realName },
                                        domProps: {
                                          textContent: _vm._s(item.realName),
                                        },
                                      }),
                                      _vm.key == "recommendationDetails"
                                        ? _c("span", {
                                            staticClass:
                                              "item item-recommend-id",
                                            attrs: {
                                              title: item.recommendationId,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendationId
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      index == 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-interview",
                                            },
                                            [_vm._v("推荐时间")]
                                          )
                                        : _vm._e(),
                                      index != 0
                                        ? _c("span", {
                                            staticClass:
                                              "item item-recommend-time",
                                            attrs: {
                                              title: item.recommendTime,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.recommendTime
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      index == 0 &&
                                      _vm.achievementDetailType == 7
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-interview",
                                            },
                                            [_vm._v("推荐主城市")]
                                          )
                                        : _vm._e(),
                                      index != 0 &&
                                      _vm.achievementDetailType == 7
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-recommend-time",
                                              attrs: { title: item.location },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.location?.length
                                                    ? item.location.join()
                                                    : "-"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      index == 0 &&
                                      _vm.achievementDetailType == 7
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-interview",
                                            },
                                            [_vm._v("推荐其他城市")]
                                          )
                                        : _vm._e(),
                                      index != 0 &&
                                      _vm.achievementDetailType == 7
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item item-recommend-time",
                                              attrs: { title: item.locations },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.locations?.length
                                                    ? item.locations.join()
                                                    : "-"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: {
                                          title: item.recommenderCFMedTime,
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.recommenderCFMedTime
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item item-recommend-time",
                                        attrs: {
                                          title: item.jobOwnerCFMedTime,
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.jobOwnerCFMedTime
                                          ),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        domProps: {
                                          textContent: _vm._s(item.status),
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "item",
                                          attrs: { title: item.candidateName },
                                        },
                                        [
                                          item.detailUrl
                                            ? _c("anchor", {
                                                attrs: {
                                                  "enable-external": true,
                                                  href: item.detailUrl,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateName
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          !item.detailUrl && item.candidateId
                                            ? _c(
                                                "a",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      title: item.candidateName,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.candidateName
                                                      ),
                                                    },
                                                  },
                                                  "a",
                                                  {
                                                    href: `#/candidateDetail/${
                                                      item.redirectId
                                                        ? item.redirectId
                                                        : item.candidateId
                                                    }`,
                                                  },
                                                  false
                                                )
                                              )
                                            : _vm._e(),
                                          !item.detailUrl && !item.candidateId
                                            ? _c("span", {
                                                attrs: {
                                                  title: item.candidateName,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.candidateName
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.title },
                                        domProps: {
                                          textContent: _vm._s(item.title),
                                        },
                                      }),
                                      _vm.key == "recommendationDetails"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.jobSource },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.jobSource
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.company },
                                        domProps: {
                                          textContent: _vm._s(item.company),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.customerName },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.customerName
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser
                                        ? [
                                            _vm.key == "recommendationDetails"
                                              ? _c("span", {
                                                  staticClass: "item",
                                                  attrs: {
                                                    title:
                                                      item.customerDomain ||
                                                      "-",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.customerDomain || "-"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm.pageType == "teamData"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.department },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.department
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", { staticClass: "item" }, [
                                        item.jobId
                                          ? _c(
                                              "a",
                                              {
                                                staticStyle: { color: "#666" },
                                                attrs: {
                                                  href: item.jobId
                                                    ? "/#/Job/" + item.jobId
                                                    : "javascript:;",
                                                  title: item.jobName,
                                                  target: item.jobId
                                                    ? "_blank"
                                                    : "_self",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.jobName))]
                                            )
                                          : _c("span", [
                                              _vm._v(_vm._s(item.jobName)),
                                            ]),
                                      ]),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.acceptUser },
                                        domProps: {
                                          textContent: _vm._s(item.acceptUser),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: {
                                          title: item.candidateSourceChannel,
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.candidateSourceChannel
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser && _vm.pageType == "teamData"
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: {
                                              title:
                                                item.businessClassificationName,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.businessClassificationName ||
                                                  "-"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm.achievementDetailType == 10 ||
                                _vm.achievementDetailType == 11
                                  ? [
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.realName },
                                        domProps: {
                                          textContent: _vm._s(item.realName),
                                        },
                                      }),
                                      index == 0
                                        ? _c("span", {
                                            staticClass: "item",
                                            domProps: {
                                              textContent: _vm._s(
                                                item.candidateName
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      index != 0
                                        ? _c(
                                            "span",
                                            { staticClass: "item" },
                                            [
                                              item.detailUrl
                                                ? _c("anchor", {
                                                    attrs: {
                                                      "enable-external": true,
                                                      href: item.detailUrl,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.candidateName
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              !item.detailUrl &&
                                              item.candidateId
                                                ? _c(
                                                    "a",
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          target: "_blank",
                                                          title:
                                                            item.candidateName,
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.candidateName
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleCandidate(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "a",
                                                      {
                                                        href: `#/candidateDetail/${
                                                          item.redirectId
                                                            ? item.redirectId
                                                            : item.candidateId
                                                        }`,
                                                      },
                                                      false
                                                    )
                                                  )
                                                : _vm._e(),
                                              !item.detailUrl &&
                                              !item.candidateId
                                                ? _c("span", {
                                                    attrs: {
                                                      title: item.candidateName,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.candidateName
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.title },
                                        domProps: {
                                          textContent: _vm._s(item.title),
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.jobSource },
                                        domProps: {
                                          textContent: _vm._s(item.jobSource),
                                        },
                                      }),
                                      index == 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _vm._v("工作地点"),
                                          ])
                                        : _vm._e(),
                                      index != 0
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.locations },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.locations?.join()
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.company },
                                        domProps: {
                                          textContent: _vm._s(item.company),
                                        },
                                      }),
                                      index == 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _vm._v("推荐至公司"),
                                          ])
                                        : _vm._e(),
                                      index != 0
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: { title: item.customerName },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.customerName
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.isCFUser
                                        ? [
                                            index == 0
                                              ? _c(
                                                  "span",
                                                  { staticClass: "item" },
                                                  [_vm._v("所属领域")]
                                                )
                                              : _vm._e(),
                                            index != 0
                                              ? _c("span", {
                                                  staticClass: "item",
                                                  attrs: {
                                                    title:
                                                      item.customerDomain ||
                                                      "-",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.customerDomain || "-"
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: { title: item.department },
                                        domProps: {
                                          textContent: _vm._s(item.department),
                                        },
                                      }),
                                      index == 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _vm._v("推荐至职位"),
                                          ])
                                        : _vm._e(),
                                      index != 0
                                        ? _c("span", { staticClass: "item" }, [
                                            item.jobId
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      color: "#666",
                                                    },
                                                    attrs: {
                                                      href: item.jobId
                                                        ? "/#/Job/" + item.jobId
                                                        : "javascript:;;;",
                                                      title: item.jobName,
                                                      target: item.jobId
                                                        ? "_blank"
                                                        : "_self",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.jobName))]
                                                )
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.jobName)),
                                                ]),
                                          ])
                                        : _vm._e(),
                                      _vm.achievementDetailType == 10 &&
                                      index == 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _vm._v("offer时间"),
                                          ])
                                        : _vm._e(),
                                      _vm.achievementDetailType == 11 &&
                                      index == 0
                                        ? _c("span", { staticClass: "item" }, [
                                            _vm._v("入职时间"),
                                          ])
                                        : _vm._e(),
                                      index != 0 &&
                                      _vm.achievementDetailType == 10
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: {
                                              title: item.operationTime,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.operationTime
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      index != 0 &&
                                      _vm.achievementDetailType == 11
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                title: _vm._f("dataFilter")(
                                                  item.hireTime
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("dataFilter")(
                                                      item.hireTime
                                                    )
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "item",
                                        attrs: {
                                          title: item.candidateSourceChannel,
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            item.candidateSourceChannel
                                          ),
                                        },
                                      }),
                                      _vm.isCFUser
                                        ? _c("span", {
                                            staticClass: "item",
                                            attrs: {
                                              title:
                                                item.businessClassificationName,
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.businessClassificationName ||
                                                  "-"
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm.key == "trackingListDetails"
                            ? [
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.userName },
                                  domProps: {
                                    textContent: _vm._s(item.userName),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.teams },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.transformTeam(item.teams)
                                    ),
                                  },
                                }),
                                index == 0
                                  ? _c("span", {
                                      staticClass: "item",
                                      domProps: {
                                        textContent: _vm._s(item.realName),
                                      },
                                    })
                                  : _vm._e(),
                                index != 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "item item-name",
                                        attrs: { title: item.realName },
                                      },
                                      [
                                        item.trackingSource == "企业库" &&
                                        item.resumeId
                                          ? _c(
                                              "a",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    title: item.realName,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.realName
                                                    ),
                                                  },
                                                },
                                                "a",
                                                {
                                                  href: `#/candidateDetail/${
                                                    item.redirectId
                                                      ? item.redirectId
                                                      : item.resumeId
                                                  }`,
                                                },
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                        item.trackingSource != "企业库"
                                          ? _c("a", {
                                              attrs: {
                                                href: `#/candidateDetail/${
                                                  item.redirectId
                                                    ? item.redirectId
                                                    : item.resumeId
                                                }`,
                                                target: "_blank",
                                                title: item.realName,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.realName
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  { staticClass: "item" },
                                  [
                                    item.inquiryLogsCount
                                      ? [
                                          item.inquiryLogsCount > 0
                                            ? _c("inquiry-log", {
                                                attrs: {
                                                  type: "trakingList",
                                                  resume: item,
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(item.inquiryLogsCount)
                                                ),
                                              ]),
                                        ]
                                      : [_vm._v(" - ")],
                                  ],
                                  2
                                ),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.title },
                                  domProps: {
                                    textContent: _vm._s(item.title || "-"),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.company },
                                  domProps: {
                                    textContent: _vm._s(item.company || "-"),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.standardizedCompany },
                                  domProps: {
                                    textContent: _vm._s(
                                      item.standardizedCompany || "-"
                                    ),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.city },
                                  domProps: {
                                    textContent: _vm._s(item.city || "-"),
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "item degree-item",
                                    attrs: {
                                      title: _vm._f("degreeFilter")(
                                        item.degree
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("degreeFilter")(item.degree)
                                      )
                                    ),
                                  ]
                                ),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.school },
                                  domProps: {
                                    textContent: _vm._s(item.school || "-"),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.yearOfExperience },
                                  domProps: {
                                    textContent: _vm._s(
                                      item.yearOfExperience || "-"
                                    ),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: { title: item.age },
                                  domProps: { textContent: _vm._s(item.age) },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: {
                                    title: item.userRecommendationCount,
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      item.userRecommendationCount
                                    ),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: {
                                    title: item.trackingDate
                                      ? item.trackingDate.slice(0, 10)
                                      : "-",
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      item.trackingDate
                                        ? item.trackingDate.slice(0, 10)
                                        : "-"
                                    ),
                                  },
                                }),
                                _c("span", {
                                  staticClass: "item",
                                  attrs: {
                                    title: item.recommendationTime
                                      ? item.recommendationTime.slice(0, 10)
                                      : "-",
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      item.recommendationTime
                                        ? item.recommendationTime.slice(0, 10)
                                        : "-"
                                    ),
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "work-table-footer" },
                  [
                    _c(
                      "el-pagination",
                      {
                        staticClass: "el-pagination-workTable",
                        attrs: {
                          "current-page": _vm.page,
                          "page-sizes": [15, 20, 50],
                          "page-size": _vm.pageSize,
                          layout: "total, sizes, prev, pager, next, slot",
                          total: _vm.pagerTotalCount,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      },
                      [
                        _c("span", { staticClass: "pagination-text" }, [
                          _c(
                            "span",
                            [
                              _vm._v("前往"),
                              _c("el-input", {
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.handlePagerJump.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.pagerJump,
                                  callback: function ($$v) {
                                    _vm.pagerJump = $$v
                                  },
                                  expression: "pagerJump",
                                },
                              }),
                              _vm._v("页"),
                            ],
                            1
                          ),
                          _c("span", { on: { click: _vm.handlePagerJump } }, [
                            _vm._v("跳转"),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }