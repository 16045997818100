export default {
    color: [
        '#FCDB72', 
        '#38BC9D', 
        '#E9E5B4', 
        '#FC8452', 
        '#B4C879', 
        '#A18BB4', 
        '#E28081', 
        '#B4C5E8', 
        '#E4CEBF', 
        '#BFDE8D', 
    ],
    tooltip: {
        trigger: 'item'
    },
    legend: {
        right: -200,
        orient: 'vertical',
        top: '25%',
        left: 'center'
    },
    series: [
        {
            name: '公司数据',
            type: 'pie',
            left: '-50%',
            radius: ['55%', '75%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'inside'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '18',
                    color: '#333',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                {value: 1048, name: '搜索引擎'},
                {value: 735, name: '直接访问'},
                {value: 580, name: '邮件营销'},
                {value: 484, name: '联盟广告'},
                {value: 300, name: '视频广告'}
            ]
        }
    ]
};