var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "tlc-container",
    },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("TrackingList候选人公司分布"),
        ]),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.noData,
              expression: "!noData",
            },
          ],
          staticClass: "chart-cell",
          attrs: { id: "trackListChart" },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.noData,
                expression: "noData",
              },
            ],
            staticClass: "empty-data",
          },
          [
            _c("span", { staticClass: "empty-img" }),
            _c("p", { staticClass: "empty-text" }, [_vm._v("暂无数据")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }